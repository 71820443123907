<script setup>
import { ref } from 'vue'
import Confetti from 'vue-confetti/src/confetti'
import draggable from 'vuedraggable'

const confetti = new Confetti({})

const playerSolution = ref([])
const solution = ref([])

const inProgress = ref(false)
const gameSetup = ref(false)

const max = ref(30)
const min = ref(0)

const sortingUpwards = ref(true)

const numberDropped = () => {
  if (playerSolution.value.join() == solution.value.join()) {
    inProgress.value = false
    confetti.start()
    window.setTimeout(() => {
      confetti.stop()
      resetGame()
      inProgress.value = true
    }, 7000)
  }
}

const randomizeArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

const resetGame = () => {
  inProgress.value = true
  solution.value = []
  if (sortingUpwards.value) {
    for (let i = min.value; i <= max.value; i++) {
      solution.value.push(i)
    }
  } else {
    for (let i = max.value; i >= min.value; i--) {
      solution.value.push(i)
    }
  }
  playerSolution.value = Array.from(solution.value)
  randomizeArray(playerSolution.value)
}
const setupGame = function () {
  gameSetup.value = true
  resetGame()
}
</script>
<template>
  <div class="game sorting">
    <div v-if="gameSetup">
      <div v-if="inProgress">
        <p>
          Drag the numbers around until they are in the correct order for
          counting
          <strong>{{ sortingUpwards ? 'upwards' : 'downwards' }}</strong>
          between {{ min }} and {{ max }}.
        </p>

        <draggable
          v-model="playerSolution"
          group="people"
          @end="numberDropped"
          item-key="id"
          :list="playerSolution"
        >
          <template #item="{element}">
            <div class="draggable-number">
              <div class="number-container">
                <div class="number-box">{{ element }}</div>
              </div>
            </div>
          </template>
        </draggable>
      </div>
      <div v-else class="winner">
        <p>Winner!</p>
      </div>
    </div>
    <div class="setup" v-else>
      <p>Choose the minimum and maximum numbers to present numbers between</p>
      <div class="field">
        <label>Minimum</label>
        <input
          class="input"
          type="number"
          v-model="min"
          @focus="$event.target.select()"
        />
      </div>
      <div class="field">
        <label>Minimum</label
        ><input
          class="input"
          type="number"
          v-model="max"
          @focus="$event.target.select()"
        />
      </div>
      <div class="field">
        <label>Up</label>
        <input
          class="radio"
          type="radio"
          v-model="sortingUpwards"
          :value="true"
        />
        <label>Down</label>
        <input
          class="radio"
          type="radio"
          v-model="sortingUpwards"
          :value="false"
        />
      </div>
      <div><div class="button is-primary" @click="setupGame">Go</div></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.game {
  .setup {
    display: table;

    .field {
      margin-bottom: 10px;
      label {
        margin-right: 10px;
        line-height: 40px;
      }
      input {
        text-align: center;
        width: 60px;
        // font-size: 30px;
      }
    }
  }
  .draggable-number {
    display: inline-block;
    font-size: 30px;
    width: 60px;
    height: 60px;
    margin: 10px;
    cursor: pointer;

    .number-container {
      display: flex;
      width: 100%;
      height: 100%;
      background: var(--base-color);
      border-radius: 15px;
      color: #fff;
      justify-content: space-around;
      align-items: center;
    }
  }

  .winner {
    p {
      font-size: 90px;
    }
  }
}
</style>
