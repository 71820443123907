<template>
  <header-bar></header-bar>
  <div class="content"><router-view></router-view></div>
  <footer-bar></footer-bar>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue'
import FooterBar from '@/components/FooterBar.vue'
export default {
  name: 'App',
  components: {
    HeaderBar,
    FooterBar
  }
}
</script>
