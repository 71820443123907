import MenuCards from "@/components/MenuCards";
import AboutPage from "@/components/AboutPage";
import RandomNumber from "@/components/RandomNumber";
import SortingGame from "@/components/SortingGame";
import AdditionGame from "@/components/AdditionGame";
import SpellingGame from "@/components/SpellingGame";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes: [
    { path: "/", name: "home", component: MenuCards },
    { path: "/about", name: "about", component: AboutPage },
    { path: "/random-game", name: "randomGame", component: RandomNumber },
    { path: "/addition-game", name: "additionGame", component: AdditionGame },
    { path: "/sorting-game", name: "sortingGame", component: SortingGame },
    { path: "/spelling-game", name: "spellingGame", component: SpellingGame },
  ],
});

export default router;
