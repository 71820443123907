<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  to: Object,
  title: String,
  description: String
})
</script>
<template>
  <div class="card is-one-third">
    <div class="card-header-title">{{ props.title }}</div>
    <div class="card-content">{{ props.description }}</div>
    <div class="card-footer">
      <router-link :to="props.to" class="button is-primary">Play</router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-header-title {
  flex: 0;
  // justify-content: space-around;
  font-size: 20px;
}
.card-content {
  text-align: left;
  flex: 1;
  opacity: 0.8;
}
.card-footer {
  border: 0;
  flex: 0;
  justify-content: right;
}
</style>
