<script setup>
import { ref } from 'vue'
import Confetti from 'vue-confetti/src/confetti'

const confetti = new Confetti({})

let currentNumber = ref(0)
let currentScore = ref(0)
let inProgress = ref(0)
let max = ref(0)
let min = ref(0)
let maxSetup = ref(0)
const winningScore = 30

inProgress.value = true
maxSetup.value = false

min.value = 0
max.value = 30

const next = function (correct) {
  if (correct) {
    currentScore.value++
  }
  if (currentScore.value == winningScore) {
    inProgress.value = false
    confetti.start()
    window.setTimeout(() => {
      confetti.stop()
      currentScore.value = 0
      generateNewNumber()
      inProgress.value = true
    }, 7000)
  }
  generateNewNumber()
}

const generateNewNumber = function () {
  let lastNumber
  while (lastNumber !== currentNumber.value) {
    lastNumber = currentNumber.value
    currentNumber.value = (
      Math.random() * (parseInt(max.value) - parseInt(min.value)) +
      parseInt(min.value)
    ).toFixed(0)
  }
}

const setup = function () {
  generateNewNumber()
  maxSetup.value = true
}
</script>

<template>
  <div v-if="maxSetup">
    <p>
      Ask your child to tell you the number, if they are correct, click
      <strong>Yes</strong> if not click <strong>No</strong>.
    </p>
    <div class="random-number">
      {{ currentNumber }}
    </div>
    <div v-if="inProgress" class="controls">
      <div>
        <div class="button no disable-touch-action" @click="next(false)">
          No
        </div>
        <div class="button yes disable-touch-action" @click="next(true)">
          Yes
        </div>
      </div>
      <progress id="file" :value="currentScore" :max="winningScore"></progress>
    </div>
  </div>
  <div class="setup" v-else>
    <p>Choose the minimum and maximum numbers to present numbers between</p>
    <div class="field">
      <label>Minimum</label>
      <input
        type="number"
        class="input"
        v-model="min"
        @focus="$event.target.select()"
      />
    </div>
    <div class="field">
      <label>Minimum</label
      ><input
        type="number"
        class="input"
        v-model="max"
        @focus="$event.target.select()"
      />
    </div>
    <div><div class="button is-primary" @click="setup">Go</div></div>
  </div>
</template>

<style lang="scss" scoped>
.setup {
  display: table;

  .field {
    margin-bottom: 10px;
    label {
      margin-right: 10px;
      line-height: 40px;
    }
    input {
      text-align: center;
      width: 60px;
      // font-size: 30px;
    }
  }
}
.random-number {
  font-size: 260px;
}
progress {
  height: 2em;
  width: 20em;
}

.button {
  border: 1px solid #ccc;
  display: inline-block;
  min-width: 100px;
  cursor: pointer;
  &.no {
    background-color: #ca0011;
    color: white;
  }
  &.yes {
    background-color: #12762b;
    color: white;
  }
  &.disable-touch-action {
    touch-action: manipulation;
  }
}
</style>
