<script setup>
import { ref } from "vue";
import Confetti from "vue-confetti/src/confetti";

const confetti = new Confetti({});

const inProgress = ref(false);
const gameSetup = ref(false);

const words_entered = ref("");
const require_entry = ref(false);
const words = ref([]);

const currentWord = ref("");
const playerWord = ref("");

const randomizeArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

const submitWord = () => {
  if (
    !require_entry.value ||
    currentWord.value.toLowerCase() == playerWord.value.toLowerCase()
  ) {
    setupNextWord();
  }
};

const speakCurrentWord = () => {
  const synth = window.speechSynthesis;
  const utterThis = new SpeechSynthesisUtterance(currentWord.value);
  synth.speak(utterThis);
};

const setupNextWord = () => {
  const currentIndex = words.value.indexOf(currentWord.value);
  if (words.value.length == currentIndex + 1) {
    inProgress.value = false;
    confetti.start();
    currentWord.value = "";
    words.value = [];
    window.setTimeout(() => {
      confetti.stop();
      resetGame();
      gameSetup.value = false;
      inProgress.value = false;
    }, 3000);
  } else {
    playerWord.value = "";
    currentWord.value = words.value[currentIndex + 1];
    speakCurrentWord();
  }
};

const resetGame = () => {
  words.value = words_entered.value.split("\n");
  inProgress.value = true;
  randomizeArray(words);
  currentWord.value = "";
  setupNextWord();
};
const setupGame = function () {
  gameSetup.value = true;
  resetGame();
};
</script>
<template>
  <div class="game spelling">
    <div v-if="gameSetup">
      <div v-if="inProgress">
        <h2>Spell the word</h2>
        <input class="input" v-model="playerWord" v-if="require_entry" />
        <div class="button is-primary" @click="speakCurrentWord">Say Again</div>
        <div class="button is-primary" @click="submitWord">
          {{ require_entry ? "Submit" : "Next" }}
        </div>
      </div>
      <div v-else class="winner">
        <p>Winner!</p>
      </div>
    </div>
    <div class="setup" v-else>
      <h2>Enter the words to read out below, each on a new line</h2>
      <div class="field">
        <textarea
          class="input"
          v-model="words_entered"
          @focus="$event.target.select()"
        ></textarea>
      </div>
      <div class="field">
        <label>User enters word?</label>
        <input
          class="checkbox"
          type="checkbox"
          v-model="require_entry"
          @focus="$event.target.select()"
        />
      </div>
      <div><div class="button is-primary" @click="setupGame">Go</div></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.game {
  .setup {
    display: table;

    textarea {
      height: 300px;
    }

    .field {
      margin-bottom: 10px;
      label {
        margin-right: 10px;
        line-height: 40px;
      }
      input {
        text-align: center;
        width: 60px;
        // font-size: 30px;
      }
    }
  }
  .draggable-number {
    display: inline-block;
    font-size: 30px;
    width: 60px;
    height: 60px;
    margin: 10px;
    cursor: pointer;

    .number-container {
      display: flex;
      width: 100%;
      height: 100%;
      background: var(--base-color);
      border-radius: 15px;
      color: #fff;
      justify-content: space-around;
      align-items: center;
    }
  }

  .winner {
    p {
      font-size: 90px;
    }
  }
}
</style>
